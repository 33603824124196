<template>
	<div id="hamburger">
		<nav>
			<input type="checkbox" id="menu-toggle" class="menu-checkbox" :checked="checked" @click="checked = true" />
			<label class="menu-hamburger" for="menu-toggle">
				<svg width="45" height="34" stroke="#fff" stroke-width="6">
					<line x1="0" y1="3" x2="45" y2="3"></line>
					<line x1="0" y1="17" x2="45" y2="17"></line>
					<line x1="0" y1="31" x2="45" y2="31"></line>
				</svg>
			</label>
			<div class="menu">
				<label class="menu-overlay" for="menu-toggle"></label>
				<div class="menu-sidebar">
					<div class="menu-header">
						<label class="menu-hamburger" for="menu-toggle">
							<svg width="36" height="36" stroke="#333" stroke-width="4">
								<line x1="3" y1="5" x2="33" y2="31"></line>
								<line x1="3" y1="31" x2="33" y2="5"></line>
							</svg>
						</label>
						<h2>Menu</h2>
					</div>
					<ul>
						<li>
							<router-link to="/">Startpagina</router-link>
						</li>
						<li v-if="authorized">
							<p @click="toggleSeasons">Seizoenen</p>
							<template v-if="showSeasons">
								<template v-for="season in seasons" v-bind:key="season.id">
									<button @click="selectSeason(season)">{{season.name}}</button>
								</template>
								<template v-if="seasons.length == 0 && !loading">
									<button disabled>Geen seizoenen gevonden</button>
								</template>
								<template v-else-if="loading">
									<LoadingCircleAnimation width="20"/>
								</template>
							</template>
						</li>
						<li v-if="authorized">
							<router-link to="/leaderboard">Ranglijst</router-link>
						</li>
						<li v-if="admin">
							<router-link to="/admin">Admin</router-link>
						</li>
						<li v-if="authorized">
							<router-link to="/logout">Uitloggen</router-link>
						</li>
						<li v-else>
							<router-link to="/register">Registreren</router-link>
						</li>
					</ul>
					<p class="version">Versie {{version}}</p>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup lang="ts">
	import {ref, inject} from 'vue';
	import {useRouter} from 'vue-router';
	import {FetchData} from "../utils/FetchData";
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';

	const checked = ref(false);
	const authorized = ref(false)
	const admin = ref(false);
	const router = useRouter();
	const version = "0.1.0";

	const showSeasons = ref(false);
	const seasons = ref([]);
	const loading = ref(true);

	const emitter = inject('emitter');
	emitter.on('unauthorized', () => onUnauthorized());
	emitter.on('authorized', () => onAuthorized());

	router.afterEach(() => {
		checked.value = false;

		if(sessionStorage.token != undefined)
			authorized.value = true;
		else
			authorized.value = false;
	});

	function onUnauthorized() {
		authorized.value = false;
		admin.value = false;
	}

	function onAuthorized() {
		authorized.value = true;
		const player = JSON.parse(sessionStorage.player);
		admin.value = player.isAdmin;
	}

	async function retrieveSeasons() {
		loading.value = true;
		const data = await FetchData("user/seasons");
		if(!data.isError)
			seasons.value = data.data;

		loading.value = false;
	}

	function toggleSeasons() {
		showSeasons.value = !showSeasons.value;
		if(seasons.value.length == 0)
			retrieveSeasons();
	}

	function selectSeason(season: object) {
		router.push({name: 'root', query: {season: season.id}});
	}
</script>

<style>
	nav {
		position: absolute;
		top: 0;
		left: 0;
	}

	.menu-hamburger svg {
		cursor: pointer;
		position: absolute;
		top: 25px;
		left: 8px;
	}

	.menu-header svg {
		cursor: pointer;
		position: absolute;
		top: 24px;
		left: 16px;
	}

	.menu-checkbox {
		opacity: 0;
	}

	.menu-checkbox:checked ~ .menu {
		width: 100vw;

		.menu-sidebar {
			transform: translateX(0%);
		}

		.menu-overlay {
			display: flex;
		}
	}

	.menu {
		width: 0px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: fixed;
		inset: 0;
		transition: width 0.5s ease-out;
		z-index: 1;

		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			text-align: left;
			font-size: 20px;
		}

		li {
			border-bottom: 1px solid lightgray;
			
			p {
				margin: 0;
				padding: 10px 20px;
				cursor: pointer;
				user-select: none;
			}

			a {
				padding: 10px 20px;
				display: block;
			}

			button{
				width: 100%;
				padding: 10px 20px 10px 60px;
				border: none;
				color: inherit;
				background-color: inherit;
				font: inherit;
				text-align: left;
			}
		}

		.version {
			position: absolute;
			bottom: 5px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	.menu-overlay {
		display: none;
		justify-content: flex-start;
		align-items: center;
		position: fixed;
		inset: 0;
		background-color: rgba(0, 0, 0, 0.75);
	}

	.menu-sidebar {
		width: 100vh;
		height: 100%;
		max-width: 320px;
		background-color: white;
		position: relative;
		transform: translateX(-100%);
		transition: transform 0.4s ease-out;
	}

	.menu-header {
		width: 100%;
		height: 84px;
		background-color: lightgray;
		display: flex;
		justify-content: center;
		align-items: center;

		h2 {
			margin: 0;
		}
	}

	nav .loading-circle-animation {
		padding: 10px 0;

		.loader {
			margin-left: 70px !important;
        }
	}
</style>