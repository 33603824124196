<template>
	<button class="content-box" @click="addCircuit">
		Circuit toevoegen
	</button>
	<template v-if="!loading">
		<template v-for="circuit in circuits" v-bind:key="circuit">
			<CircuitCard :circuit="circuit" @deleteCircuit="onDeleteCircuit" />
		</template>
	</template>
	<template v-else-if="loading">
		<LoadingCircleAnimation />
	</template>
	<ModalWindow v-show="modalVisible">
		<template v-slot:header>
			Bevestigen
		</template>
		<template v-slot:content>
			Weet je zeker dat je circuit '{{deletingCircuit.country}} - {{deletingCircuit.circuit}}' en alle bijbehorende gegevens wilt verwijderen?
		</template>
		<template v-slot:buttons>
			<button @click="confirmAccept">Ja</button>
			<button @click="confirmDeny">Nee</button>
		</template>
	</ModalWindow>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import {FetchData} from "../utils/FetchData";
	import CircuitCard from '../components/CircuitCard.vue';
	import FetchOptions from "../interfaces/FetchOptions";
	import ModalWindow from '../components/ModalWindow.vue';
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';

	const circuits = ref([]);
	const deletingCircuit = ref({});
	const modalVisible = ref(false);
	const loading = ref(true);

	onMounted(() => {
		RetrieveCircuits();
	});

	async function RetrieveCircuits() {
		const data = await FetchData('circuits');
		if(!data.isError) {
			data.data.sort((a: object, b: object) => (a.country.toLowerCase() > b.country.toLowerCase()) ? 1 : -1);
			circuits.value = data.data;
		}
		loading.value = false;
	}

	function addCircuit() {
		circuits.value.unshift({});
	}

	function onDeleteCircuit(circuit: object) {
		if(circuit.id == undefined) {
			circuits.value.shift();
			return;
		}

		modalVisible.value = true;
		deletingCircuit.value = circuit;
	}

	async function confirmAccept() {
		modalVisible.value = false;

		const options: FetchOptions = {
			method: 'DELETE'
		};
		const data = await FetchData("circuits/" + deletingCircuit.value.id, options);
		if(!data.isError)
			circuits.value = circuits.value.filter(x => x.id != deletingCircuit.value.id);
	}

	function confirmDeny() {
		modalVisible.value = false;
	}
</script>

<style>
</style>