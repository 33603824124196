<template>
	Je bent uitgelogd.
</template>

<script setup lang="ts">
	import {onMounted} from "vue";
	import {useRouter} from 'vue-router';
	import FetchOptions from "../interfaces/FetchOptions";
	import {FetchData} from "../utils/FetchData";
	import emitter from '../main';

	const router = useRouter();

	onMounted(async () => {
		if(sessionStorage.token == undefined)
			return;

		const options: FetchOptions = {
			method: 'POST'
		};

		const data = await FetchData("logout", options);
		if(!data.isError) {
			emitter.emit('unauthorized');
			router.replace('/login');
		}
	});
</script>

<style>
</style>