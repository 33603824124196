<template>
	<h1>Registreren</h1>
	<div class="content-box">
		<input type="text" placeholder="Naam" v-model="name" />
	</div>
	<div class="content-box">
		<input type="text" placeholder="Email" v-model="email" />
	</div>
	<p class="error no-margin" :class="{hidden: errorEmail.length == 0}">{{errorEmail}}</p>
	<div class="content-box">
		<input type="password" placeholder="Wachtwoord" v-model="password" />
	</div>
	<p class="error no-margin" :class="{hidden: errorPassword.length == 0}">{{errorPassword}}</p>
	<div class="content-box">
		<input type="password" placeholder="Wachtwoord herhalen" v-model="confirm" />
	</div>
	<button class="content-box" @click="register">
		Registreren
	</button>
</template>

<script setup lang="ts">
	import {ref} from "vue";
	import {useRouter} from 'vue-router';
	import {FetchToken} from "../utils/FetchData";
	import emitter from '../main';

	const name = ref('');
	const email = ref('');
	const password = ref('');
	const confirm = ref('');
	const router = useRouter();

	const errorEmail = ref('');
	const errorPassword = ref('');

	async function register() {
		const body = new URLSearchParams({
			name: name.value,
			email: email.value,
			password: password.value,
			password_confirmation: confirm.value
		});

		errorEmail.value = '';
		errorPassword.value = '';

		const data = await FetchToken('register', body, true);
		if(!data.isError) {
			sessionStorage.setItem('token', data.access_token);
			sessionStorage.setItem('player', JSON.stringify(data.player));
			emitter.emit('authorized');
			router.replace('/');
			return;
		}

		if(data.errors != undefined) {
			if(data.errors.email != undefined)
				errorEmail.value = data.errors.email[0];
			if(data.errors.password != undefined)
				errorPassword.value = data.errors.password[0];
		}
	}
</script>

<style>
	.no-margin {
		margin: 0;
	}
</style>