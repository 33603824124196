export function DateFormatter(timestamp: number, milliseconds = false) {
	const formatted = {
		date: '',
		time: '',
		picker: '',
		dateInput: ''
	};

	if(isNaN(timestamp))
		return formatted;

	if(!milliseconds)
		timestamp *= 1000;

	const date = new Date(timestamp);

	const hour = date.getHours();

	let minutes: number | string = date.getMinutes();
	if(minutes < 10)
		minutes = '0' + minutes;

	let day: number | string = date.getDate();
	if(day < 10)
		day = '0' + day;

	let month: number|string = date.getMonth() + 1;
	if(month < 10)
		month = '0' + month;

	const year = date.getFullYear();

	formatted.date = `${day}-${month}-${year}`;
	formatted.time = `${hour}:${minutes}`;
	formatted.dateInput = `${year}-${month}-${day}`;

	//Timezone offset
	date.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000 * -1));
	const ISOString = date.toISOString();
	formatted.picker = ISOString.substring(0, ISOString.length - 8);

	return formatted;
}

export function DateToTimestamp(date: string) {
	//Save date as timestamp
	const timestamp = + new Date(date) / 1000;

	return timestamp;
}