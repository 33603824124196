<template>
	<div v-if="!selectingSeason" class="admin-page">
		<button class="content-box"
				@click="seasonAdd()">
			Seizoen Toevoegen
		</button>
		<button class="content-box"
				@click="seasonEdit()">
			Seizoen Aanpassen
		</button>
		<button class="content-box"
				@click="seasonDelete()">
			Seizoen Verwijderen
		</button>
	</div>
	<div v-else class="admin-page">
		<button class="content-box"
				@click="goBack()">
			Terug
		</button>
		<SelectSeason @seasonClicked="seasonSelected" />
	</div>
	<ModalWindow v-show="modalVisible">
		<template v-slot:header>
			Bevestigen
		</template>
		<template v-slot:content>
			Weet je zeker dat je seizoen '{{deletingSeason.name}}' en alle bijbehorende gegevens wilt verwijderen?
		</template>
		<template v-slot:buttons>
			<button @click="confirmAccept">Ja</button>
			<button @click="confirmDeny">Nee</button>
		</template>
	</ModalWindow>
</template>

<script setup lang="ts">
	import {onMounted, ref} from 'vue';
	import {useRouter} from 'vue-router';
	import SelectSeason from '../components/SelectSeason.vue';
	import ModalWindow from '../components/ModalWindow.vue';
	import {FetchData} from "../utils/FetchData";
	import FetchOptions from '../interfaces/FetchOptions';

	const selectingSeason = ref(false);
	const router = useRouter();

	const modalVisible = ref(false);
	const deletingSeason = ref({});

	onMounted(() => {
		router.replace('/admin');
	});

	function seasonAdd() {
		router.push({path: '/admin/edit'});
	}

	function seasonEdit() {
		selectingSeason.value = true;
		router.push({path: '/admin', hash:'#edit'});
	}

	function seasonDelete() {
		selectingSeason.value = true;
		router.push({path: '/admin', hash: '#delete'});
	}

	function seasonSelected(season: object) {
		if(router.currentRoute.value.hash == '#delete') {
			deletingSeason.value = season;
			modalVisible.value = true;
		}
		else if(router.currentRoute.value.hash == '#edit') {
			deletingSeason.value = season;
			router.replace({path: '/admin/edit', query: {id: season.id}});
		}
	}

	function goBack() {
		deletingSeason.value = {};
		router.back();
	}

	router.beforeEach((to) => {
		if(to.hash == '#edit' || to.hash == '#delete') {
			selectingSeason.value = true;
			return true;
		}
		else {
			selectingSeason.value = false;
			return true;
		}
	});

	async function confirmAccept() {
		modalVisible.value = false;

		const options: FetchOptions = {
			method: 'DELETE'
		};
		const data = await FetchData("seasons/" + deletingSeason.value.id, options);
		if(!data.isError)
			router.back();
	}

	function confirmDeny() {
		modalVisible.value = false;
	}
</script>

<style>
	.admin-page {
		all: inherit;
		margin: 0;
		padding: 0;
		width: 100%;
	}
</style>