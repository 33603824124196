<template>
	<h1>Inloggen</h1>
	<div class="content-box">
		<input type="text" placeholder="Email" v-model="email" :disabled="loading" />
	</div>
	<div class="content-box">
		<input type="password" placeholder="Wachtwoord" v-model="password" :disabled="loading" v-on:keyup.enter="login()" />
	</div>
	<button class="content-box" @click="login" :disabled="loading">
		Inloggen
	</button>
	<router-link to="/register" class="login-link">Registreren</router-link>
	<router-link to="/forgot" class="login-link">Wachtwoord vergeten</router-link>
</template>

<script setup lang="ts">
	import {ref} from "vue";
	import {useRouter} from 'vue-router';
	import {FetchToken} from "../utils/FetchData";
	import emitter from '../main';

	const props = defineProps({
		unauthorized: {type: Boolean, required: false, default: false}
	});

	const email = ref('');
	const password = ref('');
	const router = useRouter();
	const loading = ref(false);

	async function login() {
		loading.value = true;

		const body = new URLSearchParams({
			email: email.value,
			password: password.value
		});

		const data = await FetchToken('login', body);
		if(!data.isError) {
			sessionStorage.setItem('token', data.access_token);
			sessionStorage.setItem('player', JSON.stringify(data.player));
			emitter.emit('authorized');

			if(!props.unauthorized && router.currentRoute.value.name == 'login')
				router.replace('/');
		}

		loading.value = false;
	}
</script>

<style>
	a.login-link {
		text-decoration: underline;
	}
</style>