export function SortArrayByObjectKey(array: object[], key: string, inverse = false) {
	let inverseMult = 1;
	if(inverse)
		inverseMult = -1;

	return array.sort((a, b) => {
		const keyTyped = key as keyof typeof a;
		let x = a[keyTyped] as any;
		let y = b[keyTyped] as any;

		if(typeof x == "string") {
			x = x.toLowerCase();
			y = y.toLowerCase();
		}

		if(x > y) {
			return 1 * inverseMult;
		}
		else if( x< y) {
			return -1 * inverseMult;
		}

		return 0;
	});
}

export function SortArrayByMultipleKeys(array: object[], keys: string[], inverse: boolean[]) {
	if(keys.length == 0)
		throw new Error('Keys is empty');
	if(inverse.length == 0)
		throw new Error('Inverse is empty');
	if(keys.length != inverse.length)
		throw new Error('Length keys is not equal to inverse');

	return array.sort((a, b) => {
		for(let i = 0; i < keys.length; i++) {
			let inverseMult = 1;
			if(inverse[i])
				inverseMult = -1;

			const keyTyped = keys[i] as keyof typeof a;
			let x = a[keyTyped] as any;
			let y = b[keyTyped] as any;

			if(typeof x == "string") {
				x = x.toLowerCase();
				y = y.toLowerCase();
			}

			if(x > y) {
				return 1 * inverseMult;
			}
			else if(x < y) {
				return -1 * inverseMult;
			}
		}

		return 0;
	});
}