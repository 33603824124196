<template>
	<div class="team-card card">
		<input type="text" v-model="team.name" />
		<button class="content-box" @click="deleteTeam">
			Verwijderen
		</button>
		<button class="content-box" @click="saveTeam">
			Opslaan
		</button>
	</div>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import FetchOptions from "../interfaces/FetchOptions";
	import {FetchData} from "../utils/FetchData";
	import emitter from '../main';

	const team = ref({});

	const props = defineProps<{
		team: object
	}>();

	const emit = defineEmits([
		'deleteTeam'
	]);

	onMounted(() => {
		team.value = JSON.parse(JSON.stringify(props.team));
	});

	async function saveTeam() {
		const body = team.value;
		delete body.drivers;

		console.log(body);

		if(body.id !== undefined) {
			const options: FetchOptions = {
				method: 'PATCH',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('teams/' + team.value.id, options);
			if(!data.isError)
				emitter.emit('showMessage', {message: 'Team opgeslagen', header: 'Gelukt'});
		}
		else {
			const options: FetchOptions = {
				method: 'POST',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('teams', options);
			if(!data.isError) {
				team.value = data.data;
				console.log(data.data);
				emitter.emit('showMessage', {message: 'Team toegevoegd', header: 'Gelukt'});
			}
		}
	}

	function deleteTeam() {
		emit('deleteTeam', team.value);
	}
</script>

<style>
	.team-card {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		input {
			flex-basis: 100%;
		}

		button {
			flex: 1;
		}
	}
</style>