<template>
	<transition name="modal-fade">
		<div class="modal-background">
			<div class="modal">
				<div class="top">
					<h2>
						<slot name="header">
							Error
						</slot>
					</h2>
					<slot name="content">
						Something went wrong
					</slot>
				</div>
				<div class="modal-buttons">
					<slot name="buttons">
						<button @click="close">Ok</button>
					</slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script setup lang="ts">

	const emit = defineEmits([
		'close'
	]);

	function close() {
		emit('close');
	}
</script>

<style>
	.modal-background {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.75);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
	}

	.modal {
		width: 100%;
		max-width: 700px;
		min-height: 200px;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 20px;
		background-color: #fff;

		.modal-buttons {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			padding: 10px;
			box-sizing: border-box;
		}
	}

	.modal-fade-enter,
	.modal-fade-leave-to {
		opacity: 0;
	}

	.modal-fade-enter-active,
	.modal-fade-leave-active {
		transition: opacity .5s ease;
	}
</style>