<template>
	<button class="content-box" @click="addTeam">
		Team toevoegen
	</button>
	<template v-if="!loading">
		<template v-for="team in teams" v-bind:key="team">
			<TeamCard :team="team" @deleteTeam="onDeleteTeam" />
		</template>
	</template>
	<template v-else-if="loading">
		<LoadingCircleAnimation />
	</template>
	<ModalWindow v-show="modalVisible">
		<template v-slot:header>
			Bevestigen
		</template>
		<template v-slot:content>
			Je staat op het punt om het team '{{deletingTeam.name}}' te verwijderen. Dit zal ook alle coureurs die er mee verbonden zijn ook verwijderen.
			<br />
			<br />
			Doorgaan met verwijderen?
		</template>
		<template v-slot:buttons>
			<button @click="confirmAccept">Ja</button>
			<button @click="confirmDeny">Nee</button>
		</template>
	</ModalWindow>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import {FetchData} from "../utils/FetchData";
	import TeamCard from '../components/TeamCard.vue';
	import FetchOptions from "../interfaces/FetchOptions";
	import ModalWindow from '../components/ModalWindow.vue';
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';

	const teams = ref([]);
	const deletingTeam = ref({});
	const modalVisible = ref(false);
	const loading = ref(true);

	onMounted(() => {
		RetrieveTeams();
	});

	async function RetrieveTeams() {
		const data = await FetchData('teams');
		if(!data.isError)
			teams.value = data.data;

		loading.value = false;
	}

	function addTeam() {
		teams.value.unshift({});
	}

	function onDeleteTeam(team: object) {
		if(team.id == undefined) {
			teams.value.shift();
			return;
		}

		modalVisible.value = true;
		deletingTeam.value = team;
	}

	async function confirmAccept() {
		modalVisible.value = false;

		const options: FetchOptions = {
			method: 'DELETE'
		};
		const data = await FetchData("teams/" + deletingTeam.value.id, options);
		if(!data.isError)
			teams.value = teams.value.filter(x => x.id != deletingTeam.value.id);
	}

	function confirmDeny() {
		modalVisible.value = false;
	}
</script>

<style>
</style>