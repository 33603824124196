<template>
	<h1>Wachtwoord opnieuw instellen</h1>
	<div class="content-box">
		<input type="text" placeholder="Email" v-model="email" />
	</div>
	<button class="content-box" @click="submit">
		Email sturen
	</button>
</template>

<script setup lang="ts">
	import {ref} from "vue";
	import {FetchToken} from "../utils/FetchData";
	import emitter from '../main';

	const email = ref('');

	async function submit() {
		const body = new URLSearchParams({
			email: email.value
		});

		const data = await FetchToken('forgot-password', body);
		if(!data.isError) {
			console.log(data);
			emitter.emit('showMessage', {message: 'Email is verstuurd', header: 'Gelukt'});
			return;
		}
	}
</script>

<style>
</style>