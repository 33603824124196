import FetchOptions from '../interfaces/FetchOptions';
import emitter from '../main';

export function FetchData(url: string, options: FetchOptions = {}, suppressError = false) {
	if(options.headers == undefined)
		options.headers = {};

	options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
	options.headers.Accept = 'application/json';

	let isError = false;
	let status = 200;

	return fetch(process.env.VUE_APP_API_URL + url, options)
	.then((response) => {
		const json = response.json();
		if(response.status < 200 || response.status >= 300) {
			isError = true;
			status = response.status;
		}
		return json;
	})
	.then((data) => {
		data.isError = isError
		data.status = status
		return data;
	})
	.then((data) => {
		if(data.isError) {
			if(status == 401)
				emitter.emit('unauthorized');
			else if(status == 403) {
				emitter.emit('forbidden');
				emitter.emit('showMessage','Forbidden');
			}
			else {
				console.log(data);
				if(!suppressError)
					emitter.emit('showMessage', data.msg || data.message);
			}
		}
		return data;
	})
	.catch((err) => {
		console.log(err);
		if(!isError && !suppressError)
			emitter.emit('showMessage', '');

		return {
			isError: true,
			status: status,
			message: err
		};
	});
}

export function FetchToken(url: string, body: URLSearchParams, suppressError = false) {
	const options: FetchOptions = {
		method: 'POST',
		headers: {
			Accept: 'application/json'
		},
		body: body
	}

	let isError = false;
	let status = 200;

	return fetch(process.env.VUE_APP_API_URL + url, options)
	.then((response) => {
		const json = response.json();
		if(response.status < 200 || response.status >= 300) {
			isError = true;
			status = response.status;
		}
		return json;
	})
	.then((data) => {
		data.isError = isError
		data.status = status
		return data;
	})
	.then((data) => {
		if(data.isError) {
			console.log(data);
			if(!suppressError)
				emitter.emit('showMessage', data.message);
		}
		return data;
	})
	.catch((err) => {
		console.log(err);
		if(!isError && !suppressError)
			emitter.emit('showMessage', '');

		return {
			isError: true,
			status: status,
			message: err
		};
	});
}