<template>
	<h1>Wachtwoord instellen</h1>
	<div class="content-box">
		<input type="text" placeholder="Email" v-model="email" />
	</div>
	<p class="error no-margin" :class="{hidden: errorEmail.length == 0}">{{errorEmail}}</p>
	<div class="content-box">
		<input type="password" placeholder="Wachtwoord" v-model="password" />
	</div>
	<p class="error no-margin" :class="{hidden: errorPassword.length == 0}">{{errorPassword}}</p>
	<div class="content-box">
		<input type="password" placeholder="Wachtwoord herhalen" v-model="confirm" />
	</div>
	<button class="content-box" @click="setPassword">
		Wachtwoord instellen
	</button>
</template>

<script setup lang="ts">
	import {ref} from "vue";
	import {useRouter} from 'vue-router';
	import {FetchToken} from "../utils/FetchData";
	import emitter from '../main';

	const email = ref('');
	const password = ref('');
	const confirm = ref('');
	const router = useRouter();

	const errorEmail = ref('');
	const errorPassword = ref('');

	async function setPassword() {
		const query = router.currentRoute.value.query;
		const token = query.token || '';

		const body = new URLSearchParams({
			email: email.value,
			password: password.value,
			password_confirmation: confirm.value,
			token: token as string
		});

		errorEmail.value = '';
		errorPassword.value = '';

		const data = await FetchToken('reset-password', body, true);
		if(!data.isError) {
			router.replace('/login');
			emitter.emit('showMessage', {message: 'Het nieuwe wachtwoord is opgeslagen', header: 'Gelukt'});
			return;
		}

		if(data.errors != undefined) {
			if(data.errors.email != undefined)
				errorEmail.value = data.errors.email[0];
			if(data.errors.password != undefined)
				errorPassword.value = data.errors.password[0];
		}
		else if(data.status == 400) {
			emitter.emit('showMessage', {message: data.msg, header: 'Error'});
		}
	}
</script>

<style>
</style>