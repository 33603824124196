<template>
	<img v-if="available" :src="url" />
</template>

<script setup lang="ts">
	import {onMounted, ref, watch} from "vue";

	const props = defineProps<{
		src: string | undefined
	}>();

	const url = ref('');
	const available = ref(false);

	onMounted(() => {
		loadImage();
	});

	watch(() => props.src, () => {
		loadImage();
	});

	async function loadImage() {
		url.value = process.env.VUE_APP_API_URL.replace('api', 'images/drivers') + props.src;
		setTimeout(() => {
			checkIfImageExists(url.value, exists => {
				available.value = exists;
			});
		}, 1);
	}

	function checkIfImageExists(url: string, callback: () => void) {
		const img = new Image();
		img.src = url;

		if(img.complete) {
			callback(true);
		} else {
			img.onload = () => {
				callback(true);
			};

			img.onerror = () => {
				callback(false);
			};
		}
	}

</script>

<style>
</style>