<template>
	<div class="driver-card card" :class="{replacement: driver.is_replacement}">
		<div class="image">
			<ImageComponent :src="driver.image"/>
		</div>
		<div class="content">
			<div class="input">
				<div class="input-wrapper">
					<span>Naam</span>
					<input type="text" v-model="driver.name" />
				</div>
				<template v-if="driver.id != undefined">
					<div class="input-wrapper">
						<span>Team</span>
						<span>Bij <b>{{team.name}}</b> vanaf <b>{{DateFormatter(driver.with_team_since).date}}</b></span>
						<button class="content-box" @click="editTeam">
							Team verwisselen
						</button>
					</div>
				</template>
				<template v-else>
					<div class="input-wrapper">
						<span>Team</span>
						<select v-model="newTeam">
							<option v-for="team in teams" v-bind:key="team" :value="team.id">
								{{team.name}}
							</option>
						</select>
					</div>
				</template>
				<div class="replacement">
					<input type="checkbox" v-model="driver.is_replacement" :checked="driver.is_replacement" :id="driver.id" />
					<label :for="driver.id">Is vervanger</label>
				</div>
				<input type="file" @change="onFileChange" accept="Image/*"/>
			</div>
			<div class="buttons">
				<button class="content-box" @click="deleteDriver">
					Verwijderen
				</button>
				<button class="content-box" :disabled="driver.is_replacement" @click="replaceDriver">
					Vervangen
				</button>
				<button class="content-box" @click="saveDriver">
					Opslaan
				</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import FetchOptions from "../interfaces/FetchOptions";
	import {FetchData} from "../utils/FetchData";
	import {DateFormatter} from "../utils/DateFormatter";
	import ImageComponent from '../components/ImageComponent.vue';
	import emitter from '../main';

	const driver = ref({});
	const team = ref({});
	const file = ref(null);

	const teams = ref([]);
	const newTeam = ref(1);

	const props = defineProps<{
		driver: {
			team: object
		},
		index: number
	}>();

	const emit = defineEmits([
		'updateDriver',
		'deleteDriver',
		'editTeam',
		'replaceDriver'
	]);

	onMounted(() => {
		driver.value = JSON.parse(JSON.stringify(props.driver));
		team.value = JSON.parse(JSON.stringify(props.driver.team));

		if(driver.value.id == undefined)
			RetrieveTeams();
	});

	async function RetrieveTeams() {
		const data = await FetchData('teams');
		if(!data.isError)
			teams.value = data.data;
	}

	async function saveDriver() {
		const body = driver.value;
		delete body.races;
		delete body.team;
		delete body.team_id;

		if(body.id !== undefined) {
			const options: FetchOptions = {
				method: 'PATCH',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('drivers/' + driver.value.id, options);
			if(!data.isError) {
				emitter.emit('showMessage', {message: 'Coureur opgeslagen', header: 'Gelukt'});
				uploadImage();
			}
		}
		else {
			body.team_id = newTeam.value;

			const options: FetchOptions = {
				method: 'POST',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('drivers', options);
			if(!data.isError) {
				driver.value = data.data;
				team.value = data.data.team;
				emit('updateDriver', driver.value, props.index);
				emitter.emit('showMessage', {message: 'Coureur toegevoegd', header: 'Gelukt'});
				uploadImage();
			}
		}
	}

	async function uploadImage() {
		if(file.value == null)
			return;

		const body = new FormData();
		body.append('image', file.value);
		body.append('id', driver.value.id);

		const options: FetchOptions = {
			method: 'POST',
			body: body
		};

		const data = await FetchData('images', options);
		if(data.isError)
			return;

		driver.value.image = undefined;
		setTimeout(() => {
			driver.value.image = data.img;
		}, 1);
	}

	function onFileChange(event: object) {
		if(!event.target.files.length) {
			file.value = null;
			return;
		}

		file.value = event.target.files[0];
	}

	function replaceDriver() {
		emit('replaceDriver', driver.value);
	}

	function deleteDriver() {
		emit('deleteDriver', driver.value);
	}

	function editTeam() {
		emit('editTeam', driver.value);
	}
</script>

<style>
	.driver-card {
		color: #2c3e50;
		display: flex;
		gap: 10px;

		.image {
			background-color: #d3d3d3;
			min-width: 150px;
			width: 150px;
			min-height: 200px;
			height: 200px;
			border-radius: 10px;

			img {
				max-width: 100%;
				max-height: 100%;
				border-radius: 10px;
			}
		}

		.content {
			width: 100%;
		}

		span {
			color: #fff;
			font-size: 20px;
			display: block;
		}

		input {
			margin-top: 5px;
		}

		.replacement {
			display: flex;
			gap: 10px;

			input[type=checkbox] {
				width: 27px;
				height: 27px;
				margin: 0;
			}

			label {
				color: white;
				font-size: 24px;
			}
		}

		.input {
			display: flex;
			flex-direction: column;
			gap: 10px;

			input[type=file] {
				flex-basis: 100%;
				color: white;
				padding: 0;
			}
		}

		.buttons {
			display: flex;
			gap: 10px;
			margin-top: 20px;
		}
	}

	.driver-card:not(.replacement) {
		background: #ff1801;
	}
</style>