<template>
	<div class="dropdown" :class="{'open': showDropdown}">
		<h2 :class="{right: props.right}">{{props.position + 1}}</h2>
		<button class="driver" @click="clickDropdown()">
			<p v-if="currentDriver == 0">{{position[props.position]}}</p>
			<template v-else>
				<div class="image"><ImageComponent :src="displayedDriver.image" /></div>
				<div class="name">
					<p>{{displayedDriver.name}}</p>
					<p>{{displayedDriver.team.name}}</p>
				</div>
			</template>
		</button>
		<div class="dropdown-content">
			<template v-for="driver in props.drivers" v-bind:key="driver">
				<button @click="updateDriver(props.position, driver.id)" :class="{current: driver.id == currentDriver, picked: pickedDrivers.filter(x => x == driver.id).length > 0}">
					<div class="image"><ImageComponent :src="driver.image" /></div>
					<div class="name">
						<p>{{driver.name}}</p>
						<p>{{driver.team.name}}</p>
					</div>
				</button>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
	import {readonly, ref, toRef, watch} from 'vue';
	import emitter from '../main';

	import ImageComponent from '../components/ImageComponent.vue';

	const props = defineProps<{
		drivers: object[] | undefined,
		picked: number[],
		position: number,
		current: number | undefined,
		disabled: boolean,
		right: boolean
	}>();

	const emit = defineEmits([
		'driverClicked'
	]);

	emitter.on('showDropdown', (value: number) => onShowDropdown(value));

	const position = readonly([
		"Eerste",
		"Tweede",
		"Derde"
	]);

	const emptyDriver = {
		name: 'null',
		team: {name: ''},
		image: ''
	};
	const displayedDriver = ref({});
	const currentDriver = ref(0);
	const pickedDrivers = toRef(props, 'picked');
	const showDropdown = ref(false);

	watch(() => props.current, (newValue) => {
		if(newValue == undefined)
			currentDriver.value = 0;
		else {
			currentDriver.value = pickedDrivers.value[props.position];
			const driver = props.drivers.filter(x => x.id == currentDriver.value);
			if(driver.length == 0)
				displayedDriver.value = emptyDriver;
			else
				displayedDriver.value = driver[0];
		}
	});

	function updateDriver(position: number, driverId: number) {
		currentDriver.value = driverId;
		emit('driverClicked', position, driverId);
		showDropdown.value = false;
	}

	function clickDropdown() {
		if(!props.disabled)
			showDropdown.value = !showDropdown.value;

		if(showDropdown.value)
			emitter.emit("showDropdown", props.position);
	}

	function onShowDropdown(position: number) {
		if(position != props.position)
			showDropdown.value = false;
	}
</script>

<style>
	.dropdown {
		position: relative;

		h2 {
			font-size: 36px;
			color: #B2B2B2;
			position: absolute;
			margin: 0;
			top: 50%;
			left: -7px;
			transform: translateY(-50%);
		}

		h2.right {
			right: -7px;
			left: initial;
		}

	button {
		width: 100%;
		height: 75px;
		padding: 10px;
		border: none;
		font-style: italic;
		text-align: center;
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
				margin: 0;
			}
		}

		.image {
			width: 55px;
			height: 55px;
			text-align: center;

			img {
				max-height: 100%;
				max-width: 100%;
			}
		}

		.driver {
			background-color: #4C4C4C;
			color: white;
			cursor: inherit;
			font-size: 16px;
			font-style: italic;
			text-align: center;
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				width: 100%;
			}

			p:first-of-type {
				font-weight: bold;
			}
		}

		.driver:hover {
			background-color: #6C6C6C;
		}

		.name {
			flex-basis: calc(100% - 55px);
		}

		.dropdown-content {
			display: none;
			width: 100%;
			max-height: 300px;
			overflow-y: scroll;
			position: absolute;
			z-index: 1;
			flex-direction: column;

			button.picked {
				background-color: #aaa;
			}

			button.current {
				background-color: #fff;
			}

			button.current:hover {
				color: inherit;
			}
		}
	}

	.dropdown.open .dropdown-content {
		display: flex;
	}

	.dropdown .dropdown-content {
		display: none;
	}

	@media all and (max-width: 500px) {
		.dropdown {
			.image {
				display: none;
			}

			.name {
				flex-basis: 100%;
			}
		}
	}
</style>