<template>
	<template v-if="!loading">
		<template v-for="season in seasons" v-bind:key="season.id">
			<button class="content-box" @click="selectSeason(season)">{{season.name}}</button>
		</template>
	</template>
	<template v-else-if="loading">
		<LoadingCircleAnimation width="100"/>
	</template>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import {FetchData} from "../utils/FetchData";
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';

	const emit = defineEmits([
		'seasonClicked'
	]);

	const seasons = ref([]);
	const loading = ref(true);

	async function retrieveSeasons() {
		const data = await FetchData("seasons");
		if(!data.isError)
			seasons.value = data.data;

		loading.value = false;
	}

	onMounted(() => {
		retrieveSeasons();
	});

	function selectSeason(season: object) {
		emit('seasonClicked', season);
	}

</script>

<style>
</style>