<template>
	<div class="race-points">
		<div class="bar">
			<div class="info">
				<p v-if="race.date > Date.now() / 1000">Race nog niet gereden.</p>
				<p v-else-if="pointsTotal.has(localPlayer.id)">Punten verdiend: {{pointsTotal.get(localPlayer.id) * (race.type + 1)}}</p>
				<p v-else>Punten verdiend: 0</p>
				<p>{{`${types[race.type]}: ${race.type + 1}x punten`}}</p>
			</div>
			<button @click="togglePoints">
				<svg :class="{hidden: !showPoints}" stroke-width="6">
					<line x1="3" x2="19.5" y1="8" y2="28"></line>
					<line x1="33" x2="16.5" y1="8" y2="28"></line>
				</svg>
				<svg :class="{hidden: showPoints}" stroke-width="6">
					<line x1="28" x2="8" y1="3" y2="19.5"></line>
					<line x1="28" x2="8" y1="33" y2="16.5"></line>
				</svg>
			</button>
		</div>
		<div class="points" :class="{hidden: !showPoints}">
			<hr />
			<template v-if="sortedPredictions.size > 0">
				<div v-for="[playerId, predictions] in sortedPredictions" v-bind:key="predictions" class="players">
					<div class="row" :class="{'local-player': localPlayer.id == playerId}">
						<h3>{{players.get(playerId).name}}</h3>
						<b v-if="result.length > 0">{{pointsTotal.get(playerId) * (race.type + 1)}}</b>
					</div>
					<div v-for="prediction in predictions" v-bind:key="prediction" class="row">
						<span>
							{{prediction.position + 1}}.
							{{drivers.get(prediction.driver_id).name}}
						</span>
						<template v-if="result.length > 0">
							<span v-if="prediction.points > 1">
								1+{{prediction.points - 1}}
							</span>
							<span v-else-if="prediction.points > 0">
								1
							</span>
							<span v-else>
								0
							</span>
						</template>
					</div>
				</div>
			</template>
			<div v-else class="no-players">
				<h3>Er zijn geen voorspellingen van anderen.</h3>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import {onMounted, ref, watch} from 'vue';
	import {RaceTypes} from "../utils/RaceTypes";
	import {SortArrayByMultipleKeys} from "../utils/Sorting";

	const props = defineProps<{
		predictions: object[] | undefined,
		players: object[],
		race: object
	}>();

	const showPoints = ref(false);
	const sortedPredictions = ref(new Map());
	const drivers = ref(new Map());
	const players = ref(new Map());
	const localPlayer = ref(0);
	const errorPredictions = ref([]);
	const result = ref([]);
	const pointsTotal = ref(new Map());

	const types = RaceTypes;

	onMounted(() => {
		localPlayer.value = JSON.parse(sessionStorage.player);


		props.players.forEach(x => {
			players.value.set(x.id, x);
		});
	});

	watch(() => props.predictions, () => {
		sortPredictions();
	});

	//Executed before props.prediction
	watch(() => props.race, (newValue) => {
		newValue.drivers.forEach(x => {
			drivers.value.set(x.id, x);
		});

		result.value = newValue.drivers.filter(x => x.position >= 0 && x.position < 3 && x.position != null);
	});

	function sortPredictions() {
		if(props.predictions == undefined)
			return;

		const predictions = [...props.predictions];
		predictions.sort((a, b) => {
			if(a.player_id > b.player_id)
				return 1;
			if(a.player_id < b.player_id)
				return -1;
			if(a.position > b.position)
				return 1;
			if(a.position < b.position)
				return -1;
		});

		let array = [];
		let playerId = 0;
		let total = 0;

		//Separate predictions by player and calculate points
		for(let i = 0; i < predictions.length; i++) {
			const value = predictions[i];

			if(playerId == 0)
				playerId = value.player_id;
			else if(playerId != value.player_id) {
				sortedPredictions.value.set(playerId, array);
				pointsTotal.value.set(playerId, total);
				playerId = value.player_id;
				array = [];
				total = 0;
			}

			if(drivers.value.has(value.driver_id)) {
				value.points = calculateScore(value);
				total += value.points;
				array.push(value);
			}
			else
				errorPredictions.value.push(value);

			if(i == predictions.length - 1) {
				sortedPredictions.value.set(playerId, array);
				pointsTotal.value.set(playerId, total);
			}
		}

		//Sort predictions by points
		const pointsSorted = [];
		pointsTotal.value.forEach((value, key) => {
			pointsSorted.push({id: key, points: value, name: players.value.get(key).name});
		});

		const map = new Map();
		SortArrayByMultipleKeys(pointsSorted, ['points', 'name'], [true, false]).forEach(value => {
			map.set(value.id, sortedPredictions.value.get(value.id));
		});

		sortedPredictions.value = map;
	}

	function calculateScore(prediction: object) {
		let points = 0;

		result.value.forEach(driver => {
			if(prediction.driver_id == driver.id) {
				points += 1;
				if(prediction.position == driver.position)
					points += 3 - driver.position;
				
				return;
			}
		});

		return points;
	}

	function togglePoints() {
		showPoints.value = !showPoints.value;
	}
</script>

<style>
	.race-points {
		color: white;
		margin-top: 10px;
		font-style: normal;

		.bar {
			display: flex;
			justify-content: space-between;

			.info {
				height: 50px;
				font-style: italic;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				p {
					margin: 0;
				}
			}
		}

		button {
			width: 36px;
			height: 36px;
			margin: 7px;
			padding: 0;
			border: none;
			background-color: transparent;
			position: relative;

			svg {
				width: 36px;
				height: 36px;
				stroke: #fff;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		button:hover {
			background-color: transparent;
		}

		.points {
			position: relative;
			margin-top: 40px;
			font-size: 16px;
			display: flex;
			flex-wrap: wrap;
			gap: 10px;

			hr {
				flex-basis: 100%;
				position: absolute;
				top: -20px;
				left: 0;
			}

			.players {
				padding: 0 5px;
				text-align: left;
				flex-basis: calc(50% - 5px);
				box-sizing: border-box;

				h3 {
					margin: 0;
				}

				.row {
					display: flex;
					justify-content: space-between;
				}

				.local-player {
					color: lawngreen;
				}
			}

			.no-players {
				width: 100%;
			}
		}
	}

	@media all and (max-width: 500px) {
		.points {
			padding: 0 60px;
			flex-direction: column;
			flex-wrap: nowrap;
		}
	}
</style>