<template>
	<div class="circuit-card card">
		<input type="text" v-model="circuit.country" />
		<input type="text" v-model="circuit.circuit" />
		<button class="content-box" @click="deleteCircuit">
			Verwijderen
		</button>
		<button class="content-box" @click="saveCircuit">
			Opslaan
		</button>
	</div>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import FetchOptions from "../interfaces/FetchOptions";
	import {FetchData} from "../utils/FetchData";
	import emitter from '../main';

	const circuit = ref({});

	const props = defineProps<{
		circuit: object
	}>();

	const emit = defineEmits([
		'deleteCircuit'
	]);

	onMounted(() => {
		circuit.value = JSON.parse(JSON.stringify(props.circuit));
	});

	async function saveCircuit() {
		const body = circuit.value;
		delete body.races;

		if(body.id !== undefined) {
			const options: FetchOptions = {
				method: 'PATCH',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('circuits/' + circuit.value.id, options);
			if(!data.isError)
				emitter.emit('showMessage', {message: 'Circuit opgeslagen', header: 'Gelukt'});
		}
		else {
			const options: FetchOptions = {
				method: 'POST',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('circuits', options);
			if(!data.isError) {
				circuit.value = data.data;
				emitter.emit('showMessage', {message: 'Circuit toegevoegd', header: 'Gelukt'});
			}
		}
	}

	function deleteCircuit() {
		emit('deleteCircuit', circuit.value);
	}
</script>

<style>
	.circuit-card {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		input {
			flex-basis: 100%;
		}

		button {
			flex: 1;
		}
	}
</style>