<template>
	<div class="content-box">
		<input type="text" placeholder="Naam seizoen" v-model="season.name">
	</div>
	<div class="multi-content">
		<div class="content-box code">
			Code
			<input type="text" :value="season.code" readonly />
			<button class="emoji" title="Kopiëren naar klembord" @click="copyCode">
				📋
			</button>
		</div>
		<div class="content-box player">
			Speciale rechten
			<select v-model="season.privileges">
				<option value="0">Niemand</option>
				<option v-for="player in season.players" v-bind:key="player" :value="player.id">
					{{player.name}}
				</option>
			</select>
		</div>
	</div>
	<button class="content-box" @click="saveSeason">
		Opslaan
	</button>
	<hr />
	<button class="content-box" @click="editDrivers" :disabled="loading">
		Coureurs bewerken
	</button>
	<button class="content-box" @click="editCircuits" :disabled="loading">
		Circuit toevoegen
	</button>
	<button class="content-box" @click="addRace" :disabled="loading">
		Race toevoegen
	</button>
	<hr />
	<template v-if="!loading">
		<template v-for="race in season.races" v-bind:key="race">
			<RaceCard :race="race" :admin="true" :season="season.id" :players="undefined" :predictions="undefined" :circuits="circuits" @deleteRace="onDeleteRace" />
		</template>
	</template>
	<template v-else-if="loading">
		<LoadingCircleAnimation />
	</template>
	<ModalWindow v-show="modalVisible">
		<template v-slot:header>
			Bevestigen
		</template>
		<template v-slot:content>
			Weet je zeker dat je de race '{{deletingRace.circuit.country}} - {{deletingRace.circuit.circuit}}' en alle bijbehorende gegevens wilt verwijderen?
		</template>
		<template v-slot:buttons>
			<button @click="confirmAccept">Ja</button>
			<button @click="confirmDeny">Nee</button>
		</template>
	</ModalWindow>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import {useRouter} from 'vue-router';
	import {FetchData} from "../utils/FetchData";
	import {SortArrayByObjectKey} from "../utils/Sorting";
	import RaceCard from '../components/RaceCard.vue';
	import ModalWindow from '../components/ModalWindow.vue';
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';
	import FetchOptions from "../interfaces/FetchOptions";
	import emitter from '../main';

	const router = useRouter();
	const season = ref({});
	const circuits = ref([]);
	const loading = ref(true);

	const deletingRace = ref({
		circuit: {
			country: "",
			circuit: ""
		}
	});
	const modalVisible = ref(false);

	onMounted(() => {
		const query = router.currentRoute.value.query;
		if(query.id == undefined)
			return;

		const id = parseInt(query.id);
		if(id <= 0)
			return;

		retrieveSeason(id);
		retrieveCircuits();
	});

	async function retrieveSeason(id: number) {
		const data = await FetchData("seasons/" + id);
		if(data.isError) {
			const route = router.currentRoute.value;
			router.replace(route.path);
			return;
		}

		setSeason(data.data);
		loading.value = false;
	}

	async function retrieveCircuits() {
		const data = await FetchData('circuits');
		if(!data.isError)
			circuits.value = SortArrayByObjectKey(data.data, 'name');
	}

	async function saveSeason() {
		const body = season.value;
		delete body.players;
		delete body.races;

		if(body.id != undefined) {
			const options: FetchOptions = {
				method: 'PATCH',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};
			const data = await FetchData("seasons/" + season.value.id, options);
			if(!data.isError) {
				emitter.emit('showMessage', {message: 'Seizoen opgeslagen', header: 'Gelukt'});

				setSeason(data.data);
			}
		}
		else {
			const options: FetchOptions = {
				method: 'POST',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};
			const data = await FetchData("seasons", options);
			if(data.isError)
				return;

			emitter.emit('showMessage', {message: 'Seizoen toegevoegd', header: 'Gelukt'});
			router.replace({path: '/admin/edit', query: {id: data.data.id}});

			setSeason(data.data);
		}
	}

	function setSeason(seasonData: object) {
		seasonData.races = SortArrayByObjectKey(seasonData.races, 'date');
		season.value = seasonData;
		const privileges = seasonData.players.filter(x => x.pivot.privileges == 1);
		if(privileges.length == 0)
			season.value.privileges = 0;
		else
			season.value.privileges = privileges[0].id;
	}

	function copyCode() {
		const domain = window.location.host;
		const code = season.value.code;
		const text = `${domain}/invite/${code}`;
		navigator.clipboard.writeText(text)
		.catch((error) => {
			alert.error(`Could not copy text: ${error}`);
		});
	}

	function editDrivers() {
		router.push({path: '/admin/edit/drivers'});
	}

	function editCircuits() {
		router.push({path: '/admin/edit/circuits'});
	}

	function addRace() {
		season.value.races.unshift({
			date: (Date.now() / 1000) + new Date().getTimezoneOffset() * 60 * -1,
			drivers: []
		});
	}

	function onDeleteRace(race: object) {
		if(race.id == undefined) {
			season.value.races.shift();
			return;
		}

		deletingRace.value = race;
		modalVisible.value = true;
	}

	async function confirmAccept() {
		modalVisible.value = false;

		const options: FetchOptions = {
			method: 'DELETE'
		};
		const data = await FetchData("races/" + deletingRace.value.id, options);
		if(!data.isError)
			season.value.races = season.value.races.filter(x => x.id != deletingRace.value.id);
	}

	function confirmDeny() {
		modalVisible.value = false;
	}
</script>

<style>
	.multi-content {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 20px;

		.code {
			width: auto;
			display: flex;
			gap: 10px;

			input {
				width: 115px;
			}
		}

		.player {
			text-wrap: nowrap;
			display: flex;
			gap: 10px;
		}
	}

	@media all and (max-width: 720px) {
		.multi-content {
			flex-wrap: wrap;

			.code {
				width: 100%;

				input {
					width: 100%;
				}
			}
		}
	}
</style>