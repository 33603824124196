<template>
	<h1>Ranglijst</h1>
	<div class="content-box">
		<select v-model="selected" @change="retrievePredictions()">
			<option v-for="season in seasons" v-bind:key="season" :value="season.id">
				{{season.name}}
			</option>
		</select>
	</div>
	<template v-if="!loading">
		<template v-for="[playerId, points] in playerTotal" v-bind:key="playerId">
			<div class="content-box leaderboard">
				<p>{{players.get(playerId)}}</p>
				<p>{{points}}</p>
			</div>
		</template>
	</template>
	<template v-else-if="loading">
		<LoadingCircleAnimation />
	</template>
</template>

<script setup lang="ts">
	import {onMounted, ref} from 'vue';
	import {FetchData} from "../utils/FetchData";
	import {SortArrayByMultipleKeys} from "../utils/Sorting";
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';

	const loading = ref(true);
	const seasons = ref([]);
	const selected = ref(0);
	const predictions = ref([]);
	const results = ref([]);
	const playerTotal = ref(new Map());

	let players = new Map();

	onMounted(() => {
		retrieveSeasons();
	})

	async function retrieveSeasons() {
		const data = await FetchData("user/seasons?with_players=1");
		if(!data.isError) {
			seasons.value = data.data;
			selected.value = seasons.value[seasons.value.length - 1].id;
			retrievePredictions();
		}
		else
			loading.value = false;
	}

	async function retrievePredictions() {
		if(selected.value == 0)
			return;

		loading.value = true;
		const data = await FetchData('predictions?season=' + selected.value);
		if(!data.isError) {
			predictions.value = SortArrayByMultipleKeys(data.data.predictions, ['race_id', 'player_id', 'position'], [false, false, false]);
			results.value = SortArrayByMultipleKeys(data.data.results, ['race_id', 'position'], [false, false]);
			calculateScores();
		}
		else
			loading.value = false;
	}

	function calculateScores() {
		//Clear scores and set players
		players.clear();
		playerTotal.value.clear();

		seasons.value.filter(x => x.id == selected.value)[0].players.forEach(player => {
			players.set(player.id, player.name);
		});

		players.forEach((value, key) => {
			playerTotal.value.set(key, 0);
		});

		//Iterate and compare scores to results
		const filteredPredictions = new Map();
		results.value.forEach(result => {
			let relevantPredictions;
			if(!filteredPredictions.has(result.race_id)) {
				relevantPredictions = predictions.value.filter(x => x.id == result.race_id);
				filteredPredictions.set(result.race_id, relevantPredictions);
			}
			else
				relevantPredictions = filteredPredictions.get(result.race_id);

			relevantPredictions.forEach(prediction => {
				let points = 0;
				if(prediction.driver_id == result.driver_id) {
					points += 1
					if(prediction.position == result.position)
						points += 3 - result.position;
				}

				points *= result.type + 1;
				points += playerTotal.value.get(prediction.player_id);

				playerTotal.value.set(prediction.player_id, points);
			});
		});

		//Sort players by total points
		const playersSorted = [];
		playerTotal.value.forEach((value, key) => {
			playersSorted.push({id: key, points: value, name: players.get(key).name});
		});

		const map = new Map();
		SortArrayByMultipleKeys(playersSorted, ['points', 'name'], [true, false]).forEach(value => {
			map.set(value.id, playerTotal.value.get(value.id));
		});

		playerTotal.value = map;
		loading.value = false;
	}
</script>

<style>
	.leaderboard {
		display: flex;
		justify-content: space-between;

		p {
			margin: 0;
		}
	}
</style>