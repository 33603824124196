<template>
	<button class="content-box" @click="editTeams">
		Teams bewerken
	</button>
	<button class="content-box" @click="addDriver">
		Coureur toevoegen
	</button>
	<template v-if="!loading">
		<template v-for="(driver, index) in drivers" v-bind:key="driver">
			<DriverCard :driver="driver" :index="index" @updateDriver="onUpdateDriver" @deleteDriver="onDeleteDriver" @editTeam="onEditTeam" @replaceDriver="onReplaceDriver" />
		</template>
	</template>
	<template v-else-if="loading">
		<LoadingCircleAnimation />
	</template>
	<ModalWindow v-show="modalDeleteVisible">
		<template v-slot:header>
			Bevestigen
		</template>
		<template v-slot:content>
			Weet je zeker dat je coureur '{{deletingDriver.name}}' en alle bijbehorende gegevens wilt verwijderen?
		</template>
		<template v-slot:buttons>
			<button @click="confirmAccept">Ja</button>
			<button @click="confirmDeny">Nee</button>
		</template>
	</ModalWindow>
	<ModalWindow v-show="modalTeamVisible">
		<template v-slot:header>
			Team verwisselen
		</template>
		<template v-slot:content>
			<span>Team</span>
			<select v-model="newTeam">
				<option v-for="team in teams" v-bind:key="team" :value="team.id">
					{{team.name}}
				</option>
			</select>
			<span>Bij team vanaf</span>
			<input v-model="selectedDate" class="date" type="date" />
			<p class="error">{{errorMessage}}</p>
		</template>
		<template v-slot:buttons>
			<button @click="teamSave">Opslaan</button>
			<button @click="teamCancel">Annuleren</button>
		</template>
	</ModalWindow>
	<ModalWindow v-show="modalReplacementVisible">
		<template v-slot:header>
			Coureur vervangen
		</template>
		<template v-slot:content>
			<span>Coureur</span>
			<select v-model="replacer">
				<option v-for="driver in availableDrivers" v-bind:key="driver" :value="driver.id">
					{{driver.name}}
				</option>
			</select>
			<span>Vervangt vanaf</span>
			<input v-model="selectedDate" class="date" type="date" />
			<p class="error">{{errorMessage}}</p>
		</template>
		<template v-slot:buttons>
			<button @click="replaceSave">Opslaan</button>
			<button @click="replaceStop">Stoppen met vervangen</button>
			<button @click="replaceCancel">Annuleren</button>
		</template>
	</ModalWindow>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import {useRouter} from 'vue-router';
	import {FetchData} from "../utils/FetchData";
	import DriverCard from '../components/DriverCard.vue';
	import FetchOptions from "../interfaces/FetchOptions";
	import ModalWindow from '../components/ModalWindow.vue';
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';
	import {DateFormatter, DateToTimestamp} from "../utils/DateFormatter";
	import emitter from '../main';

	const drivers = ref([]);
	const router = useRouter();
	const deletingDriver = ref({});
	const modalDeleteVisible = ref(false);
	const loading = ref(true);

	const teams = ref([]);
	const editingDriver = ref({});
	const modalTeamVisible = ref(false);
	const modalReplacementVisible = ref(false);
	const selectedDate = ref();

	const newTeam = ref(0);
	const replacer = ref(0);
	const availableDrivers = ref([]);
	const errorMessage = ref('');

	onMounted(() => {
		RetrieveDrivers();
	});

	async function RetrieveDrivers() {
		const data = await FetchData('drivers');
		if(!data.isError) {
			//data.data.sort((a: object, b: object) => (a.team_id > b.team_id) ? 1 : -1);
			data.data.sort((a: object, b: object) => {
				if(a.team_id > b.team_id)
					return 1;
				else if(a.team_id < b.team_id)
					return -1;

				if(a.with_team_since > b.with_team_since)
					return 1;
				else if(a.with_team_since < b.with_team_since)
					return -1;

				if(a.name > b.name)
					return 1;

				return -1;
			});
			drivers.value = data.data;
		}

		loading.value = false;
	}

	async function RetrieveTeams() {
		const data = await FetchData('teams');
		if(!data.isError)
			teams.value = data.data;
	}

	function editTeams() {
		router.push(router.currentRoute.value.path + '/teams');
	}

	function addDriver() {
		drivers.value.unshift({team: {}, 'with_team_since': Date.now() / 1000});
	}

	function onUpdateDriver(driver: object, index: number) {
		drivers.value[index] = driver;
	}

	function onDeleteDriver(driver: object) {
		if(driver.id == undefined) {
			drivers.value.shift();
			return;
		}

		modalDeleteVisible.value = true;
		deletingDriver.value = driver;
	}

	async function confirmAccept() {
		modalDeleteVisible.value = false;

		const options: FetchOptions = {
			method: 'DELETE'
		};
		const data = await FetchData("drivers/" + deletingDriver.value.id, options);
		if(!data.isError)
			drivers.value = drivers.value.filter(x => x.id != deletingDriver.value.id);
	}

	function confirmDeny() {
		modalDeleteVisible.value = false;
	}

	function onEditTeam(driver: object) {
		if(teams.value.length == 0)
			RetrieveTeams();

		editingDriver.value = driver;
		modalTeamVisible.value = true;
		errorMessage.value = '';

		selectedDate.value = DateFormatter(Date.now(), true).dateInput;
	}

	async function teamSave() {
		errorMessage.value = '';

		if(newTeam.value == 0) {
			errorMessage.value = "Er is geen team gekozen.";
			return;
		}

		if(newTeam.value == editingDriver.value.team_id) {
			errorMessage.value = "Het nieuwe team is hetzelfde als het oude team."
			return;
		}

		if(editingDriver.value.id == undefined)
			return;

		const body = {
			'team_id': newTeam.value,
			'with_team_since': DateToTimestamp(selectedDate.value)
		}

		const options: FetchOptions = {
			method: 'PATCH',
			headers: {
				"Content-Type": 'application/json'
			},
			body: JSON.stringify(body)
		};

		const data = await FetchData('drivers/' + editingDriver.value.id, options);
		if(!data.isError) {
			drivers.value.push(data.data);
			modalTeamVisible.value = false;
			emitter.emit('showMessage', {message: 'Team verwisseld', header: 'Gelukt'});
		}
	}

	function teamCancel() {
		modalTeamVisible.value = false;
	}

	function onReplaceDriver(driver: object) {
		editingDriver.value = driver;
		modalReplacementVisible.value = true;
		errorMessage.value = '';

		selectedDate.value = DateFormatter(Date.now(), true).dateInput;

		if(driver.replaced_by == null)
			replacer.value = 0;
		else
			replacer.value = driver.replaced_by;

		availableDrivers.value = drivers.value.filter(x => x.is_replacement && x.team_id == driver.team_id);
		if(availableDrivers.value.length == 0)
			availableDrivers.value.push({name: "Geen geldige vervangers gevonden", id: 0});
	}

	async function replaceSave() {
		errorMessage.value = '';

		if(replacer.value == 0) {
			errorMessage.value = "Geen geldige vervanger geselecteerd";
			return;
		}

		const body = {
			id: editingDriver.value.id,
			'replaced_by': replacer.value,
			'replaced_after': DateToTimestamp(selectedDate.value)
		};

		const options: FetchOptions = {
			method: 'PATCH',
			headers: {
				"Content-Type": 'application/json'
			},
			body: JSON.stringify(body)
		};

		const data = await FetchData('drivers/' + editingDriver.value.id, options);
		if(!data.isError) {
			modalReplacementVisible.value = false;
			emitter.emit('showMessage', {message: 'Coureur vervangen', header: 'Gelukt'});
		}
	}

	async function replaceStop() {
		const body = {
			id: editingDriver.value.id,
			'replaced_by': null,
			'replaced_after': DateToTimestamp(selectedDate.value)
		};

		const options: FetchOptions = {
			method: 'PATCH',
			headers: {
				"Content-Type": 'application/json'
			},
			body: JSON.stringify(body)
		};

		const data = await FetchData('drivers/' + editingDriver.value.id, options);
		if(!data.isError) {
			modalReplacementVisible.value = false;
			emitter.emit('showMessage', {message: 'Coureur wordt niet meer vervangen', header: 'Gelukt'});
		}
	}

	function replaceCancel() {
		modalReplacementVisible.value = false;
	}
</script>

<style>
</style>