<template>
	<div class="race-card card">
		<div class="header white" :class="{upcoming: props.race.date > Date.now() / 1000}" v-if="!props.admin">
			<h2>{{props.race.circuit.country}}</h2>
			<h3>{{props.race.circuit.circuit}}</h3>
			<p>{{dateString}}</p>
			<p>{{types[props.race.type]}}</p>
			<hr />
		</div>
		<div class="header inputs" v-else>
			<select v-model="race.circuit_id" class="circuit">
				<option v-for="circuit in props.circuits" v-bind:key="circuit" :value="circuit.id">
					{{circuit.country}} - {{circuit.circuit}}
				</option>
			</select>
			<select v-model="race.type" class="type">
				<option v-for="(type, index) in types" v-bind:key="type" :value="index">
					{{type}}
				</option>
			</select>
			<input v-model="selectedDate" class="date" type="datetime-local"/>
		</div>
		<div class="drivers">
			<div class="guessed" v-if="!props.admin">
				<p class="red">Voorspelling</p>
				<template v-for="index in 3" v-bind:key="index">
					<DriverDropdown :drivers="race.drivers" :position="index - 1" :picked="pickedDrivers" :current="pickedDrivers[index - 1]" :disabled="props.race.date <= Date.now() / 1000" :right="false" @driverClicked="updateDriver" />
				</template>
			</div>
			<div class="result">
				<p class="red">Uitslag</p>
				<template v-for="index in 3" v-bind:key="index">
					<DriverDropdown :drivers="race.drivers" :position="index - 1" :picked="pickedResult" :current="pickedResult[index - 1]" :disabled="!(props.admin || privileges) || props.race.date > Date.now() / 1000" :right="!props.admin" @driverClicked="updateResult" />
				</template>
			</div>
		</div>
		<div class="buttons" v-if="props.admin">
			<button class="content-box" @click="deleteRace">
				Verwijderen
			</button>
			<button class="content-box" @click="saveRace">
				Opslaan
			</button>
		</div>
		<template v-else>
			<RacePoints :predictions="props.predictions" :players="players" :race="race"/>
		</template>
	</div>
</template>

<script setup lang="ts">
	import {onMounted, ref, watch} from 'vue';
	import FetchOptions from "../interfaces/FetchOptions";
	import {FetchData} from "../utils/FetchData";
	import {DateFormatter, DateToTimestamp} from "../utils/DateFormatter";
	import {SortArrayByObjectKey} from "../utils/Sorting";
	import {RaceTypes} from "../utils/RaceTypes";
	import DriverDropdown from '../components/DriverDropdown.vue';
	import RacePoints from '../components/RacePoints.vue';
	import emitter from '../main';

	const props = defineProps<{
		race: {
			circuit: object,
			drivers: object[]
		},
		admin: boolean,
		season: number,
		players: object[] | undefined,
		predictions: object[] | undefined,
		circuits: object[] | undefined
	}>();

	const emit = defineEmits([
		'deleteRace'
	]);

	const dateString = ref('');
	const race = ref({});

	const selectedDate = ref();

	const pickedDrivers = ref(new Array(3));
	const pickedResult = ref(new Array(3));

	const localPlayer = ref(0);
	const privileges = ref(false);

	const types = RaceTypes;

	onMounted(() => {
		localPlayer.value = JSON.parse(sessionStorage.player);

		const formatted = DateFormatter(props.race.date);
		dateString.value = `${formatted.date}, ${formatted.time}`;
		selectedDate.value = formatted.picker;

		race.value = JSON.parse(JSON.stringify(props.race));

		let drivers = race.value.drivers.filter(driver => driver.position != null);
		drivers = SortArrayByObjectKey(drivers, 'position');
		for(let i = 0; i < Math.min(drivers.length, 3); i++)
			pickedResult.value[drivers[i].position] = drivers[i].id;

		checkPrivileges();
	});

	watch(() => props.predictions, () => {
		if(props.predictions?.length == 0)
			return;

		const player = JSON.parse(sessionStorage.player);
		if(player.id == undefined)
			return;

		let array = props.predictions.filter(x => x.player_id == player.id);
		array = SortArrayByObjectKey(array, 'position');

		for(let i = 0; i < array.length; i++)
			pickedDrivers.value[i] = array[i].driver_id;
	});

	function checkPrivileges() {
		privileges.value = false;

		if(localPlayer.value.isAdmin) {
			privileges.value = true;
			return;
		}

		if(typeof (props.players) == 'object') {
			const players = props.players as object[];
			const privilegesPlayers = players.filter(x => x.pivot.privileges == 1);
			privilegesPlayers.forEach(player => {
				if(player.id == localPlayer.value.id)
					privileges.value = true;
			});
		}
	}

	function updateDriver(position: number, driverId: number) {
		pickedDrivers.value = swapDrivers(pickedDrivers.value, position, driverId);
		saveGuess();
	}

	function updateResult(position: number, driverId: number) {
		pickedResult.value = swapDrivers(pickedResult.value, position, driverId);
		saveResult();
	}

	function swapDrivers(array: (number | undefined)[], position: number, driverId: number) {
		const temp = [...array];
		const oldValue = array[position];
		let oldPosition = undefined;
		for(let i = 0; i < Math.min(array.length, 3); i++) {
			if(array[i] == driverId) {
				oldPosition = i;
				break;
			}
		}

		temp[position] = driverId;
		if(oldPosition != undefined)
			temp[oldPosition] = oldValue;

		return temp;
	}

	async function saveRace() {
		const body = JSON.parse(JSON.stringify(race.value));
		delete body.drivers;
		delete body.circuit;

		body.date = DateToTimestamp(selectedDate.value);
		body.seasons = [props.season];

		if(body.id !== undefined) {
			const options: FetchOptions = {
				method: 'PATCH',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('races/' + race.value.id, options);
			if(!data.isError)
				emitter.emit('showMessage', {message: 'Race opgeslagen', header: 'Gelukt'});
		}
		else {
			const options: FetchOptions = {
				method: 'POST',
				headers: {
					"Content-Type": 'application/json'
				},
				body: JSON.stringify(body)
			};

			const data = await FetchData('races', options);
			if(!data.isError) {
				race.value = data.data;
				emitter.emit('showMessage', {message: 'Race toegevoegd', header: 'Gelukt'});
			}
		}
	}

	function deleteRace() {
		emit('deleteRace', race.value);
	}

	function saveGuess() {
		pickedDrivers.value.forEach((driver, position) => saveDriver(driver, position));
	}

	function saveResult() {
		pickedResult.value.forEach((driver, position) => saveDriver(driver, position, true));
	}

	async function saveDriver(driverId: number|undefined, position: number, result = false) {
		if(driverId == undefined)
			return;

		if(position >= 3)
			return;

		const body = {
			race_id: props.race.pivot.id,
			driver_id: driverId,
			position: position
		};

		const options: FetchOptions = {
			method: 'POST',
			headers: {
				"Content-Type": 'application/json'
			},
			body: JSON.stringify(body)
		};

		if(!result) {
			FetchData('predictions', options);
		}
		else {
			options.method = 'PATCH';
			console.log(await FetchData('race_driver/' + race.value.id, options));
		}
	}
</script>

<style>
	.race-card {
		font-size: 16px;
		font-style: italic;

		.white {
			color: #fff;
		}

		.red {
			color: #FF1801;
			font-weight: bold;
		}

		.header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 10px;

			h2 {
				font-size: 32px;
				color: #FF1801;
			}

			h3 {
				font-weight: normal;
			}

			h2, h3 {
				flex-basis: 100%;
				margin: 0;
			}

			p {
				font-weight: bold;
				flex-basis: 100%;
				margin: 0;
			}
		}

		.header.upcoming {
			h2 {
				color: lawngreen;
			}
		}

		.inputs {
			flex-direction: column;
			gap: 10px;

			* {
				text-align: left;
			}
		}

		.drivers {
			display: flex;
			gap: 10px;

			> div {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}

		.buttons {
			margin-top: 10px;
			display: flex;
			gap: 10px;
		}
	}
</style>