<template>
	<h1 class="season-header">{{season.name}}</h1>
	<div class="filters">
		<button class="content-box" :class="{selected: filterType == 0}" @click="changeFilter(0)">Alles ({{raceCount[0]}})</button>
		<button class="content-box" :class="{selected: filterType == 1}" @click="changeFilter(1)">Komend  ({{raceCount[1]}})</button>
		<button class="content-box" :class="{selected: filterType == 2}" @click="changeFilter(2)">Gereden  ({{raceCount[2]}})</button>
	</div>
	<template v-for="race in season.races" v-bind:key="race">
		<RaceCard :race="race" :admin="false" :season="0" :players="season.players" :predictions="predictionsRace.get(race.id)" :circuits="undefined" :class="{hidden: (filterType == 1 && race.date <= Date.now() / 1000) || (filterType == 2 && race.date > Date.now() / 1000)}"/>
	</template>
	<template v-if="season.id == undefined && !loading">
		<div class="content-box">
			Geen seizoenen gevonden
		</div>
	</template>
	<template v-else-if="loading">
		<LoadingCircleAnimation/>
	</template>
</template>

<script setup lang="ts">
	import {onMounted, ref} from "vue";
	import {useRouter} from 'vue-router';
	import {FetchData} from "../utils/FetchData";
	import {SortArrayByObjectKey} from "../utils/Sorting";
	import RaceCard from '../components/RaceCard.vue';
	import LoadingCircleAnimation from '../components/LoadingCircleAnimation.vue';

	const router = useRouter();
	const season = ref({});
	const loading = ref(true);
	const filterType = ref(0);
	const raceCount = ref([0, 0, 0]);
	const predictions = ref([]);
	const predictionsRace = ref(new Map());

	onMounted(() => {
		if(sessionStorage.token != undefined)
			retrieveSeason();
	});

	router.afterEach((to) => {
		if(!to.path.endsWith('/'))
			return;

		if(sessionStorage.token != undefined)
			retrieveSeason();
	});


	async function retrieveSeason() {
		loading.value = true;

		let id = 0
		const query = router.currentRoute.value.query;
		if(query.season != undefined) {
			id = parseInt(query.season);
		}

		if(id > 0) {
			const data = await FetchData("user/seasons/" + id);
			if(!data.isError)
				season.value = data.data;
		}
		else {
			const data = await FetchData("user/seasons");
			if(data.isError) {
				loading.value = false;
				return;
			}

			if(data.data.length > 0)
				season.value = data.latest;
		}

		if(Object.keys(season.value).length > 0) {
			retrievePredictions(season.value.id);

			let racesFinshed = season.value.races.filter(x => x.date <= Date.now() / 1000);
			let racesUpcoming = season.value.races.filter(x => x.date > Date.now() / 1000);

			raceCount.value = [season.value.races.length, racesUpcoming.length, racesFinshed.length];

			racesFinshed = SortArrayByObjectKey(racesFinshed, 'date', true);
			racesUpcoming = SortArrayByObjectKey(racesUpcoming, 'date');

			season.value.races = racesUpcoming.concat(racesFinshed);
		}

		loading.value = false;
	}

	async function retrievePredictions(seasonId: number) {
		const data = await FetchData('predictions/?season=' + seasonId);
		if(!data.isError) {
			predictions.value = data.data.predictions;

			season.value.races.forEach(race => {				
				const filter = predictions.value.filter(x => x.race_id == race.id);
				predictionsRace.value.set(race.id, filter);
			});

			/*if(data.data.length == 0)
				return;

			const player = JSON.parse(sessionStorage.player);
			if(player.id == undefined)
				return;

			let array = data.data.filter(x => x.player_id == player.id);
			array = SortArrayByObjectKey(array, 'position');

			for(let i = 0; i < array.length; i++)
				pickedDrivers.value[i] = array[i].driver_id;*/
		}
	}

	function changeFilter(type: number) {
		filterType.value = type;
	}
</script>

<style>
	.filters {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: 20px;

		.selected {
			background-color: #ff1801;
			color: white;
		}
	}

	@media all and (max-width: 720px) {
		.season-header {
			margin: 20px 0 0 0;
		}

		.filters {
			flex-wrap: wrap;
		}
	}
</style>