<template>
	<h1>{{error}}</h1>
	<template v-if="showInput">
		<div class="content-box">
			<input type="text" placeholder="Code" maxlength="8" v-model="input"/>
		</div>
		<button class="content-box" @click="enterCode">Invoeren</button>
	</template>
</template>

<script setup lang="ts">
	import {ref, onMounted} from 'vue';
	import {useRouter} from 'vue-router';
	import {FetchData} from "../utils/FetchData";
	import FetchOptions from '../interfaces/FetchOptions';

	const error = ref('');
	const showInput = ref(false);
	const input = ref('');
	const router = useRouter();

	onMounted(() => {
		lookForCode();
	});

	function lookForCode() {
		const code = router.currentRoute.value.params.catchAll;
		if(code == undefined) {
			error.value = 'Code invoeren';
			showInput.value = true;
			return;
		}

		if(code.length == 0) {
			error.value = 'Code invoeren';
			showInput.value = true;
			return;
		}

		checkCode(code as string);
	}

	function enterCode() {
		checkCode(input.value);
	}

	async function checkCode(code: string) {
		if(code.length == 0)
			return;

		const options: FetchOptions = {
			method: 'POST',
			headers: {
				"Content-Type": 'application/json'
			},
			body: JSON.stringify({code: code})
		}

		const data = await FetchData('codes', options, true);
		if(!data.isError) {
			router.replace({path: '/', query: {season: data.data.id}})
			return;
		}

		error.value = data.msg;
	}
</script>

<style>
</style>