<template>
	<NavBar title="Formule 1 App"/>
	<div class="main-content">
		<template v-if="authorized || authorizationNotRequired">
			<router-view></router-view>
		</template>
		<template v-else>
			<LoginPage :unauthorized="true" />
		</template>
		<ModalWindow v-show="modalVisible" @close="modalClose">
			<template v-slot:content v-if="modalMessage.length > 0">
				{{modalMessage}}
			</template>
			<template v-slot:header v-if="modalHeader.length > 0">
				{{modalHeader}}
			</template>
		</ModalWindow>
	</div>
</template>

<script setup lang="ts">
	import {ref, inject, onMounted} from 'vue';
	import {useRouter} from 'vue-router';
	import NavBar from './components/NavBar.vue';
	import ModalWindow from './components/ModalWindow.vue';
	import LoginPage from './pages/LoginPage.vue';

	const emitter = inject('emitter');
	emitter.on('showMessage', (value: {message: '', header: ''} | string) => onShowMessage(value));
	emitter.on('unauthorized', () => onUnauthorized());
	emitter.on('authorized', () => onAuthorized());
	emitter.on('forbidden', () => onForbidden());

	const router = useRouter();
	const modalVisible = ref(false);
	const modalMessage = ref('');
	const modalHeader = ref('');
	const authorized = ref(false);
	const authorizationNotRequired = ref(true);

	const allowedRoutes = [
		'login',
		'register',
		'forgot',
		'reset'
	];

	onMounted(() => {
		if(sessionStorage.token != undefined) {
			authorized.value = true;
			emitter.emit('authorized');
		}
	});

	router.beforeEach(async (to) => {
		if(authorized.value == false) {
			if(allowedRoutes.filter(x => x == to.name).length == 0)
				return {name: 'login'};

			return true;
		}
		else {
			if(to.name == 'login')
				return {name: 'root'};
		}

		if(!to.path.startsWith('/admin'))
			return true;

		const player = JSON.parse(sessionStorage.player);
		if(!player.isAdmin)
			return false;

		return true;
	});

	router.beforeResolve(async (to) => {
		if(allowedRoutes.filter(x => x == to.name).length > 0)
			authorizationNotRequired.value = true;
		else
			authorizationNotRequired.value = false;
	})

	function modalClose() {
		modalVisible.value = false;
	}

	function onShowMessage(value: {message: '', header: ''} | string) {
		modalVisible.value = true;
		if(typeof(value) === 'string') {
			modalMessage.value = value;
			modalHeader.value = '';
			return;
		}		

		modalMessage.value = value.message;
		modalHeader.value = value.header;
	}

	function onUnauthorized() {
		authorized.value = false;
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('player');
	}

	function onAuthorized() {
		authorized.value = true;
	}

	function onForbidden() {
		router.push('/');
	}
</script>

<style>
	* {
		font-family: "Montserrat", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#app {
		text-align: center;
		color: #2c3e50;
	}

	body {
		background-color: #cfcfc4;
		margin: 0 0 20px 0;
	}

	.main-content {
		margin: auto;
		padding: 96px 13px 0;
		max-width: 720px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	a:visited {
		color: inherit;
	}

	.content-box {
		background-color: #fff;
		border: 2px solid #333;
		width: 100%;
		height: 60px;
		font-size: 24px;
		color: inherit;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 10px;
		box-sizing: border-box;
	}

	.card {
		background-color: #000;
		padding: 23px;
		width: 100%;
		box-sizing: border-box;
	}

	input {
		width: 100%;
		padding: 5px;
		margin: 0;
		color: #2c3e50;
		text-align: center;
		font-size: 24px;
		box-sizing: border-box;
	}

	select {
		width: 100%;
		font-size: 24px;
		color: inherit;
	}

	button {
		cursor: pointer;
	}

	button.emoji {
		font-size: 24px;
		border: none;
		background-color: transparent;
		padding: 0;
		margin: 0;
		height: 32px;
		min-width: 32px;
		display: flex;
		justify-content: space-around;
	}

	button:hover:not(.emoji) {
		background-color: #ff1801;
		color: #fff;
		font-weight: 700;
	}

	button:disabled {
		background-color: #aaa;
	}

	button:disabled:hover {
		background-color: #aaa;
		font-weight: inherit;
	}

	hr {
		border-width: 2px 0 0 0;
		margin: 0;
		width: 100%;
	}

	.error {
		color: red !important;
	}

	.hidden {
		display: none !important;
	}
</style>
