import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router';
import mitt from 'mitt';
import App from './App.vue'
import MainPage from './pages/MainPage.vue';
import AdminPage from './pages/AdminPage.vue';
import EditSeasonPage from './pages/EditSeasonPage.vue';
import DriversPage from './pages/DriversPage.vue';
import TeamsPage from './pages/TeamsPage.vue';
import CircuitsPage from './pages/CircuitsPage.vue';
import LoginPage from './pages/LoginPage.vue';
import LogoutPage from './pages/LogoutPage.vue';
import RegisterPage from './pages/RegisterPage.vue';
import ForgotPasswordPage from './pages/ForgotPasswordPage.vue';
import ResetPasswordPage from './pages/ResetPasswordPage.vue';
import InvitePage from './pages/InvitePage.vue';
import LeaderboardPage from './pages/LeaderboardPage.vue';

const routes = [
	{path: '/', name: 'root', component: MainPage},
	{path: '/admin', name: 'admin', component: AdminPage},
	{path: '/admin/edit', name: 'season', component: EditSeasonPage},
	{path: '/admin/edit/drivers', name: 'drivers', component: DriversPage},
	{path: '/admin/edit/drivers/teams', name: 'teams', component: TeamsPage},
	{path: '/admin/edit/circuits', name: 'circuits', component: CircuitsPage},
	{path: '/login', name: 'login', component: LoginPage},
	{path: '/logout', name: 'logout', component: LogoutPage},
	{path: '/register', name: 'register', component: RegisterPage},
	{path: '/forgot', name: 'forgot', component: ForgotPasswordPage},
	{path: '/reset', name: 'reset', component: ResetPasswordPage},
	{path: '/invite', name: 'invite', component: InvitePage},
	{path: '/invite/:catchAll(.*)', name: 'code', component: InvitePage},
	{path: '/leaderboard', name: 'leaderboard', component: LeaderboardPage}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

const emitter = mitt();

const app = createApp(App);
app.use(router);
app.provide('emitter', emitter);
app.mount('#app');

export default emitter;
